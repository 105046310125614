import React, { memo, useEffect } from 'react'
import { NewsCard } from '@elements'
import { navigate } from '@reach/router'
import { Pagination } from '@components'
import styles from './News.module.scss'

interface NewsProps {}

const News: React.FC<NewsProps> = props => {
  useEffect(() => {
    navigate('/')
  })
  return null

  // <>
  //   <div className={styles.news_heading}>
  //     <h1 className={styles.news_head}>Новости</h1>
  //     <div className={styles.news_line}></div>
  //   </div>
  //   <section className={styles.news_container}>
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //     <NewsCard />
  //   </section>
  //   <Pagination />
  // </>
}

export default memo(News)
